import React from "react"
import { Link, graphql } from "gatsby"
import { SEO } from "../components/Seo"
import { rhythm, scale } from "../utils/typography"
import { Header } from "../components/Header"
import { EmailSignup } from "../components/EmailSignup"
import { DiabeticRed } from "../utils/colors"
import theme from "../utils/theme"
import { useMediaQuery } from "react-responsive"
import { Footer } from "../components/Footer"
import ColorHash from "color-hash"
import { OpenInNewTab } from "../components/OpenInNewTab"

const BodyFontFamily = theme.bodyFontFamily
  .map((font, i) =>
    i !== theme.bodyFontFamily.length - 1 ? `'${font}'` : font
  )
  .join(", ")

const TagLink = props => {
  const [isHovering, setHovering] = React.useState(false)

  const styles = {
    fontStyle: "italic",
    backgroundColor: isHovering
      ? new ColorHash().hex(props.tags)
      : new ColorHash({ lightness: 0.9, saturation: 0.5 }).hex(props.tags),
    color: isHovering ? "white" : "black",
    borderRadius: 16,
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: rhythm(1 / 2),
    display: "inline-block",
  }

  return (
    <Link
      style={styles}
      to={"/" + props.tags}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {"#" + props.tags}
    </Link>
  )
}

function Card(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const Required = ["slug", "title", "subtitle", "description", "tags"]
  for (const field of Required) {
    if (!(field in props)) {
      throw new Error(`Missing "${field}" in props.`)
    }
  }

  return (
    <Link
      className="Card"
      to={props.slug}
      style={{
        padding: 0,
        display: "block",
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      {/*
      <PreviewImage previewImage={props.previewImage} />
      */}
      <div
        style={{
          paddingLeft: isMobile ? rhythm(1 / 2) : rhythm(1),
          paddingRight: isMobile ? rhythm(1 / 2) : rhythm(1),
          paddingTop: rhythm(1),
          paddingBottom: rhythm(1),
        }}
      >
        <TagLink tags={props.tags} />
        <h2
          style={{
            color: DiabeticRed,
            marginTop: 0,
            marginBottom: rhythm(1 / 4),
          }}
        >
          {props.title}
        </h2>
        <h3
          style={{
            fontStyle: "italic",
            fontWeight: 300,
            fontFamily: BodyFontFamily,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
            marginBottom: rhythm(1),
            ...scale(1 / 4),
          }}
        >
          {props.subtitle}
        </h3>
        {false && (
          <p style={{ color: "black", marginTop: 0, marginBottom: rhythm(1) }}>
            {props.description}
          </p>
        )}
        <div className="CallToAction" style={{ color: DiabeticRed }}>
          Read more ⟶
        </div>
      </div>
    </Link>
  )
}

function Homepage(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { growthNodes } = props

  return (
    <main
      style={{
        margin: "0 auto",
        paddingRight: rhythm(1 / 2),
        paddingLeft: rhythm(1 / 2),
        maxWidth: 600,
      }}
    >
      <Hero />
      <ul
        style={{
          listStyle: "none",
          margin: 0,
        }}
      >
        {growthNodes.map(node => (
          <li
            style={{
              marginBottom: isMobile ? rhythm(1 / 2) : rhythm(1),
            }}
            key={node.id}
          >
            <Card
              title={node.frontmatter.title}
              subtitle={node.frontmatter.subtitle}
              slug={node.fields.slug}
              emojiClassName={node.frontmatter.emojiClassName}
              description={node.frontmatter.description}
              previewImage={node.frontmatter.previewImageUrl}
              tags={node.frontmatter.tags}
            />
          </li>
        ))}
      </ul>
    </main>
  )
}

function Hero() {
  return (
    <>
      <h1>
        Hi{" "}
        <span role="img" aria-label="hand wave">
          👋
        </span>
        , I'm Jason.
      </h1>
      <p>
        I'm a software engineer based in NYC, and I have a deep interest in
        games programming (
        <OpenInNewTab href="/">see my portfolio</OpenInNewTab>).
      </p>
      <p>I also write thoughtful posts on a variety of topics.</p>
      <p>Click on any of the tags below to read about a specific topic:</p>
      <p>
        {[
          'professional-game-programmer',
          // "first10games",
          "etudes-for-the-web-browser",
          "frontend",
          "productivity",
          "impact",
          "unity3d",
          "game-design",
          "shaders",
          "game-juice",
          "augmented-reality",
          "marketing",
          "pico8",
        ].map(tag => (
          <span style={{ marginRight: rhythm(1 / 4) }}>
            <TagLink key={tag} tags={tag} />
          </span>
        ))}
      </p>
      <p>
        And if you'd like to receive notifications for new posts, subscribe to
        be notified when I publish something new:
      </p>
      <EmailSignup
        copy=" "
        altColor
        urlPath="/homepage-seeking-a-gameplay-programmer"
      />
      <h2
        style={{
          fontFamily: BodyFontFamily,
          textAlign: "center",
          fontWeight: 100,
          fontStyle: "italic",
        }}
      >
        Or view past content below ↓
      </h2>
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { contentType: { ne: "new-portfolio" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            description
            previewImageUrl {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            tags
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default function Page({ data }) {
  const { site } = data
  const growthNodes = data.allMdx.edges
    .map(edge => edge.node)
    .filter(node => node.frontmatter.tags !== "do-not-show")
  // console.log("growth nodes:", growthNodes)

  // See gatsby-config.js for actual values.
  const { title, description } = site.siteMetadata

  return (
    <>
      <SEO // This guy has a componentWillMount deprecation.
        description={description}
        imageUrl="https://jasont-co.nucleartide.vercel.app/avocado_small.jpg"
        imageAlt={title}
        title=""
      />
      <Header />
      <Homepage growthNodes={growthNodes} /*previewImage={fluid}*/ />
      <Footer />
    </>
  )
}
